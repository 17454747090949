<template>
  <Modal class="mobile-s file-uploader" ref="modal" @close="modalClose" :settings="settings">
    <div class="unlock-creator" slot="header">
      <div class="modal-logo">
        <iconic name="mf_logo" />
      </div>
    </div>
    <div :class="`post-review-confirm`">
      <form id="user-file" class="options" v-on:submit.prevent>
        <SingleCard class="user-files-file __toDelete" :style="`--cover:url(${encode_url(getFileCover(file))})`">
          <div class="cover"></div>
          <div class="name">{{ file.name }}</div>
        </SingleCard>
        <Spacer num="1" />
        <div>{{ $locale["delete_file_confirm"] }}</div>
        <Spacer num="1" />
        <div v-if="$loading" class="file-uploader-uploading" tcenter dcolor>
          <iconic name="gspinner" />
          <span>{{ " " + $locale["deleting"] }}...</span>
        </div>
        <Confirm
          v-else
          :confirm="{ accept: { label: $locale['delete'] }, cancel: { label: $locale['cancel'] } }"
          @cancel="modalClose"
          @accept="deleteFile"
        />
      </form>
    </div>
  </Modal>
</template>

<script>
export default {
  data: function() {
    return {
      settings: {
        opened: true,
        class: "view menu bottom mk_head",
        animation: "fadeIn",
      },
    };
  },
  methods: {
    modalClose: function() {
      this.$store.state.user.newFile = null;
      this.$store.state.user.fileEdit = null;
      this.$store.state.user.fileDelete = null;
    },
    deleteFile: async function() {
      this.isLoading({ state: true, lockapp: true });
      try {
        const formData = { _id: this.file._id };
        const req = await this.$http.post(`${this.$apiHost}user/files/delete`, formData);
        this.deleteFileEnd(req.data);
      } catch (error) {
        this.ialert.go({ title: "Error", message: error });
      }
      this.isLoading(false);
    },
    deleteFileEnd: function({ success, message, total }) {
      if (!success) {
        this.ialert.go({ title: "Error", message });
        return;
      }

      if (success) {
        this.$store.state.user.files.items = this.$store.state.user.files.items.filter((f) => f._id !== this.file._id);
        this.$store.state.user.files.total = total;
        this.modalClose();
      }
    },
  },
  computed: {
    file: function() {
      return this.$store.state.user.fileDelete;
    },
  },
};
</script>
